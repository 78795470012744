Vue.asyncComponent('cms-active-workspace', {
    data() {
        return {
            workspace: this.$userInterfaceHandler.getUserInterface()?.workspace,
            workspaceAllRedirectPath: this.$userInterfaceHandler.getUserInterface()?.workspaceAllRedirectPath,
            workspaceAllAccess: this.$userInterfaceHandler.getUserInterface()?.workspaceAllAccess
        }
    },
    methods: {
        switchWorkspace(workspaceId) {
            this.$userInterfaceHandler.switchWorkspace(workspaceId);
        }
    }
}, 'cms/layout/cms-active-workspace.html');