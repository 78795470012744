/**
 * UserInterfaceHandler
 */
export class UserInterfaceHandler {
    constructor() {
        this.getUserInterface = this.getUserInterface.bind(this);
        this.setUserInterface = this.setUserInterface.bind(this);
        this.switchWorkspace = this.switchWorkspace.bind(this);
        this.updateUserInterface = this.updateUserInterface.bind(this);
    }

    /**
     * Get Interface
     *
     * @returns {*}
     */
    getUserInterface() {
        if (! this.userInterface) {
            this.updateUserInterface();
        }

        return this.userInterface;
    }

    /**
     * Set interface
     * @param interface
     */
    setUserInterface(userInterface) {
        this.userInterface = userInterface;

        // If wa have an accentColor defined in the userInterface
        if (this.userInterface.workspace.accentColor) {
            // we change the bottom border of the header to the accent color
            document.querySelector('body').style.setProperty('--header-border-bottom-color', this.userInterface.workspace.accentColor.hexCode);
        }
    }

    /**
     * Update the interface
     * We get the new interface from the backend
     * @returns {Promise<void>}
     */
    async updateUserInterface() {
        this.vue = window.AppKit.getApp();

        const result = await this.vue.$get(this.vue.$getSetting('paths.baseCms') + '/user-interface');
        this.setUserInterface(result);
        return this.userInterface;
    }

    /**
     * Notify the backend that we want to switch from workspace
     * @param workspaceId
     * @returns {Promise<void>}
     */
    async switchWorkspace(workspaceId) {
        let path = this.vue.$getSetting('paths.baseCms') + '/workspace/switch';
        if(workspaceId) {
            path += '/' + workspaceId
        }

        const result = await this.vue.$get(path);

        this.vue.$handleActions(result);
    }
}

export default {
    install: (Vue) => {
        Vue.prototype.$userInterfaceHandler = (new UserInterfaceHandler());
    }
}
