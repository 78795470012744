import {get, post} from '../../../spine-appkit-vue/src/utils/data-manager';
import {getSetting} from '../../../spine-appkit-vue/src/utils/settings';

/**
 * AuthService
 */
export class AuthService {
    /**
     * Constructor
     */
    constructor() {
        this.user = null;
    }

    /**
     * Check if we are authenticated
     * @returns {boolean}
     */
    isAuthenticated() {
        return this.user !== null;
    }

    /**
     * Get the current usser
     * @returns {null|*}
     */
    getAuthUser() {
        return this.user;
    }

    /**
     * Set the current user
     * @param user
     */
    setUser(user) {
        this.user = user;
    }

    /**
     * Check if we are authenticated
     * @returns {Promise<boolean>}
     */
    async getIsAuthenticated()  {
        let result = await get(getSetting('paths.baseAuth') + '/user/get-authenticated');

        if (result && result.success) {
            this.user = result.user;
        } else {
            this.user = null;
        }

        return this.isAuthenticated();
    }
}

export default {
    install: (Vue) => {
        Vue.prototype.$authService = (new AuthService());
    }
}
