Vue.asyncComponent('cms-password-recovery-form',{
    data() {
        return {
            showSuccess: false,
            loading: false,
            error: null,
            email: null,
        }
    },
    methods: {
        async submit() {
            this.loading = true;
            this.error = null;

            // construct the post data
            const data = {
                email: this.email
            }

            // do submit call
            let result = await this.$post(this.$getSetting('paths.baseAuth') + '/user/password-recovery/', data);
            result = {
                success: true,
                error: 'We konden geen account vinden met dit email adress',
            };

            this.loading = false;

            if (!result.success) {
                this.error = result.error;
            } else {
                this.showSuccess = true;
            }
        },
        toLogin() {
            this.$router.push({name: 'login'});
        }
    }
}, 'cms/form/cms-password-recovery-form.html');