/**
 * Workspace
 */
export class WorkspaceHandler {
    constructor() {
        this.getWorkspace = this.getWorkspace.bind(this);
        this.setWorkspace = this.setWorkspace.bind(this);
    }

    /**
     * get the active workspace
     * @returns {*}
     */
    getWorkspace() {
        return this.workspace;
    }

    /**
     * Set the active workspace
     * @param workspace
     */
    setWorkspace(workspace) {
        this.workspace = workspace;
    }
}

export default {
    install: (Vue) => {
        Vue.prototype.$workspaceHandler = (new WorkspaceHandler());
    }
}
