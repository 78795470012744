Vue.asyncComponent('cms-share-modal', {
    data() {
        return {
            passwordProtected: false,
            password: null,
            expirationDate: null,
            loading: false,
            shareLink: null,
            errors: {}
        }
    },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        verticalId: {
            type: String,
            required: true,
        },
        blueprintId: {
            type: String,
        }
    },
    model: {
        prop: 'open',
        event: 'change'
    },
    methods: {
        async generateShareLink() {
            this.loading = true;
            const path = this.$getSetting('paths.baseCms') + '/public-share-link/create';

            const params = {
                verticalId: this.verticalId
            };

            if (this.blueprintId) {
               params.blueprintId = this.blueprintId;
            }

            const body = {
                passwordProtected: this.passwordProtected,
                password: this.password,
                expirationDate: this.expirationDate,
            };

            let result = await this.$post(path, body, params);
            this.loading = false;

            if (result.success) {
                this.shareLink = result.link;
                this.$copyToClipboard(this.shareLink);
            } else {
                this.errors = result.errors;
                console.log(this.errors);
            }
        },
        /**
         * Open the public share link grid in a new tab
         */
        goToGrid() {
            const routeData = this.$router.resolve({
                name: 'grid',
                params: {
                    gridDefinition: 'gird',
                    bundle: 'public-share-link',
                }
            });
            console.log('hier');
            window.open(routeData.href, '_blank');
        }
    }
}, 'cms/share/cms-share-modal.html');