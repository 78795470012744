Vue.asyncComponent('cms-password-reset-form',{
    data() {
        return {
            loading: false,
            showSuccess: false,
            showExpired: false,
            showForm: false,
            errors: {
                password: null,
                passwordConfirm: null,
            },
            password: null,
            passwordConfirm: null,
        }
    },
    methods: {
        async submit() {
            this.loading = true;
            this.error = null;

            // construct the post data
            const data = {
                token: this.token,
                password: this.password,
                passwordConfirm: this.passwordConfirm
            }

            // do submit call
            let result = await this.$post(this.$getSetting('paths.baseAuth') + '/user/password-reset/', data);
            result = {
                success: true,
                errors: {
                    password: 'We konden geen account vinden met dit email adress',
                    passwordConfirm: 'We konden geen account vinden met dit email adress'
                },
            };

            this.loading = false;

            if (!result.success) {
                this.errors = result.errors;
            } else {
                this.showSuccess = true;
            }
        },
        toLogin() {
            this.$router.push({name: 'login'});
        },
        async checkToken() {
            this.loading = true;

            // construct the post data
            const params = {
                token: this.$route.query.token,
            }

            // do submit call
            let result = await this.$get(this.$getSetting('paths.baseAuth') + '/user/password-reset-token/', params);
            result = {
                success: true,
            };

            this.loading = false;

            if (result.success) {
                this.showForm = true;
            } else {
                this.showExpired = true;
            }
        }
    },
    created() {
        this.checkToken();
    }
}, 'cms/form/cms-password-reset-form.html');