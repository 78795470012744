Vue.asyncComponent('cms-list', {
    computed: {
        bundle() {
            return this.$route.params.bundle;
        },
        listDefinition() {
            return this.$route.params.listDefinition;
        }
    },
    methods: {
        goToForm(data) {
            this.$router.push({ name: 'form' , params: {
                    bundle: data.bundleId,
                    definition: data.definitionId,
                    objectId: data.objectId,
                }});
        },
        goToGrid(data) {
            this.$router.push({ name: 'grid' , params: {
                    bundle: data.bundleId,
                    gridDefinition: data.definitionId,
                }});
        }
    }
}, 'cms/pages/cms-list.html');