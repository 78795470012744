Vue.asyncComponent('cms-grid', {
    computed: {
        bundle() {
            return this.$route.params.bundle;
        },
        gridDefinition() {
            return this.$route.params.gridDefinition;
        },
        query () {
            return this.$route.query;
        }
    },
    methods: {
        updateQuery(query) {
            this.$router.push({...this.$route, query: query});
        },
        goToForm(data) {
            this.$router.push({ name: 'form' , params: {
                    bundle: data.bundleId,
                    definition: data.definitionId,
                    objectId: data.objectId,
                }});
        },
        goToGrid(data) {
            this.$router.push({ name: 'grid' , params: {
                    bundle: data.bundleId,
                    gridDefinition: data.definitionId,
                }});
        }
    }
}, 'cms/pages/cms-grid.html');