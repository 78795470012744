import {getSetting} from './settings';

export default class ErrorHandler {

    constructor() {
        this.errors = [];

        this.sendError = this.sendError.bind(this);
    }

    /**
     * Handle error
     * @param error
     * @returns {*}
     */
    handleError(error) {
        switch (error.code) {
            // case 400:
            //     return sendError('Error 400');
            case 401:
                // redirect to login screen
                if (getSetting('paths.login')) {
                    window.location = getSetting('paths.login');
                }
                return this.sendError('Error 401');
            // case 404:
            //     return sendError('Error 404');
            case 500:
                return this.sendError({code: 500, message:'Oeps er ging iets mis'});
            default:
                return this.sendError(error);
        }
    }

    /**
     * Send error
     * @param error
     */
    sendError(error) {
        this.errors.push(error);
    }
}