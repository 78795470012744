Vue.asyncComponent('cms-localization-button', {
    props: {
        locales: {
            type: Array|String,
            required: false,
        }
    },
    computed: {
        currentLocales() {
            if (this.locales instanceof Array) {
                return this.locales;
            }

            return this.locales.split(',').map(locale => {
                return {
                    label: locale,
                    active: this.$getSetting('lang') === locale,
                }
            });
        }
    },
    methods: {
        localeItemClicked(language) {
            this.$emit('click', language);
        }
    }
}, 'cms/locale/cms-localization-button.html');