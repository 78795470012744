Vue.asyncComponent('cms-page-not-found', {
    computed: {
        bundle() {
            return this.$route.params.bundle;
        },
        gridDefinition() {
            return this.$route.params.gridDefinition;
        }
    },
    methods: {
        goToForm(data) {
            this.$router.push({ name: 'form' , params: {
                    bundle: data.bundleId,
                    definition: data.definitionId,
                    objectId: data.objectId,
                }});
        },
        goToGrid(data) {
            this.$router.push({ name: 'grid' , params: {
                    bundle: data.bundleId,
                    gridDefinition: data.definitionId,
                }});
        }
    }
}, 'cms/pages/cms-page-not-found.html');