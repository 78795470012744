Vue.asyncComponent('cms-share-menu-item', {
    data() {
        return {
            openModal: false,
        }
    },
    props: {
        title: {
            type: String,
            required: false,
        },
        active: {
            type: Boolean,
            required: false,
        },
        showArrow: {
            type: Boolean,
            required: false,
            default: true,
        },
        colorMode: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        },
        image: {
            type: String,
            required: false,
        },
        description: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        verticalId: {
            type: String,
            required: true
        },
        blueprintId: {
            type: String,
            required: false
        },
    }
}, 'cms/share/cms-share-menu-item.html');