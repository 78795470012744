Vue.asyncComponent('cms-form', {
    computed: {
        bundle() {
            return this.$route.params.bundle;
        },
        definition() {
            return this.$route.params.definition;
        },
        objectId() {
            return this.$route.params.objectId;
        },
        relationString() {
            return this.$route.params.relationString;
        }
    },
    methods: {
        goToGrid(data) {
            if (this.objectId !== 'new') {
                this.$emit('dataObjectChanged', this.objectId);
            } else {
                this.$emit('changed', data);
            }

            if(this.$route.name === 'blueprint-form') {
                this.$router.push({ name: 'blueprint'})
                this.$emit('save', data);
                return;
            }

            this.$router.push({ name: 'grid' , params: {
                bundle: data.bundleId,
                gridDefinition: data.definitionId,
            }});
        },
        close() {
            this.$router.back();
        }
    }
}, 'cms/pages/cms-form.html');