Vue.asyncComponent('cms-published-toggle', {
    props: {
        published: {
            default: false,
        },
        publishedStart: {
            type: String,
            required: false,
            default: null,
        },
        publishedEnd: {
            type: String,
            required: false,
            default: null,
        }
    },
    methods: {
        openPublishedStep() {
            this.$emit('doEdit');
        },
        togglePublished() {
            this.$emit('toggle', this.published);
        }
    }
}, 'cms/published/cms-published-toggle.html');