class CmsRouter {
    constructor() {
        this.getRoutesPrepend = this.getRoutesPrepend.bind(this);
        this.getRoutesAppend = this.getRoutesAppend.bind(this);
        this.setRoutes = this.setRoutes.bind(this);
        this.beforeEachAuthChecks = this.beforeEachAuthChecks.bind(this);
        this.setActiveRouter = this.setActiveRouter.bind(this);
    }

    /**
     * Get all the cms routes we need to prepend
     * @returns Array
     */
    getRoutesPrepend() {
        return [
            {
                path: '/login',
                name: 'login',
                component: {
                    template: '<cms-login-form/>'
                },
                meta: {
                    title: "Login"
                },
            },
            {
                path: '/password-recovery',
                name: 'password-recovery',
                component: {
                    template: '<cms-password-recovery-form/>'
                },
                meta: {
                    title: "Recovery"
                },
            },
            {
                path: '/password-reset',
                name: 'password-reset',
                component: {
                    template: '<cms-password-reset-form/>'
                },
                meta: {
                    title: "Reset"
                },
            },
            {
                path: '/profile',
                name: 'profile',
                component: {
                    template: '<cms-profile/>'
                },
                meta: {
                    title: "Profile"
                },
            },
        ]
    }

    /**
     * Get all the cms routes we need to append
     * @returns Array
     */
    getRoutesAppend() {
        return [
            ...Vue.blueprintRoutes,
            {
                path: '/list/:listDefinition/:bundle',
                name: 'list',
                component: {
                    template: '<cms-list/>',
                },
                meta: {
                    title: "List"
                },
                children: [
                    {
                        path: ':definition/:objectId/:relationString?',
                        name: 'list-form',
                        components:{
                            childview: {
                                template: '<cms-form v-on="$listeners"/>',
                            }
                        },
                    }
                ]
            },
            {
                path: '/:gridDefinition/:bundle',
                name: 'grid',
                component: {
                    template: '<cms-grid/>'
                },
                meta: {
                    title: "Grid"
                },
                children: [
                    {
                        path: ':definition/:objectId/:relationString?',
                        name: 'form',
                        components:{
                            childview: {
                                template: '<cms-form v-on="$listeners"/>',
                            }
                        },
                    }
                ]
            },
            {
                path: "/*",
                component: {
                    template: '<cms-page-not-found/>',
                }
            }
        ]
    }

    /**
     * Set Routes
     * We will set the workspace prefix for all the authenticated routes
     * And we will add the routes for the cms packe
     * @param routes
     * @returns {*[]}
     */
    setRoutes(routes) {
        // now we add the routes where we don't need authentication
        return [...this.getRoutesPrepend(), ...routes, ...this.getRoutesAppend()];
    }

    /**
     * Check if we need to be authenticated for some routes
     * @param to
     * @param from
     * @param next
     * @returns {Promise<void>}
     */
    async beforeEachAuthChecks(to, from, next) {
        const authRoutes = ['login','password-reset','password-recovery'];
        // Do the auth call to check if we are already logged in
        const isAuthenticated = await this.router.app.$authService.getIsAuthenticated();

        // if not authenticated and the route is a route where authentication is needed we redirect to login
        if (!authRoutes.includes(to.name) && !isAuthenticated) {
            next({name: 'login'});
        }

        // if authenticated and the route isen't a route where authentication is needed we redirect to home
        if (authRoutes.includes(to.name) && isAuthenticated) {
            // redirect to the home page
            next({path: '/'});
        }
        next();
    }

    /**
     * Set the active router so we can manipulate the beforeEach behavior
     * @param router
     */
    setActiveRouter(router) {
        this.router = router;

        // set some check for each route
        this.router.beforeEach(this.beforeEachAuthChecks);
    }
}

Vue.cmsRouter = (new CmsRouter());