Vue.asyncComponent('cms-seo-preview',{
    props: {
        id: {
            type: String,
            default: '',
            required: true,
        },
        previewType: {
            type: String,
            required: true
        },
        url: {
            type: String,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        image: {
            type: String,
        },
    },
    computed: {
        /**
         * For google preview we render the host different then the path
         * @returns {string}
         */
        hostAndProtocol() {
            const url = (new URL(this.url));
            return url.protocol + '://' + url.host
        },
        /**
         * For google preview we render the host different then the path
         * @returns {string}
         */
        host() {
            const url = (new URL(this.url));
            return url.host
        },

        /**
         * For google preview we render the path different then the host
         * @returns {string}
         */
        path() {
            const url = (new URL(this.url));
            return url.pathname.replace('/', ' › ');
        }
    }
}, 'cms/seo/cms-seo-preview.html');