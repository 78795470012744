// Routes
import './routes';

// Auth handler
import authHandler from './utils/auth-handler';
Vue.use(authHandler);

// Workspace handler
import workspaceHandler from './utils/workspace-handler';
Vue.use(workspaceHandler);

// Interface handler
import userInterfaceHandler from './utils/user-interface-handler';
Vue.use(userInterfaceHandler);

// Components
import './components/form/CmsLoginForm';
import './components/form/CmsPasswordRecoveryForm';
import './components/form/CmsPasswordResetForm';

// locale
import './components/locale/CmsLocalizationButton';

// share
import './components/share/CmsShareMenuItem.js';
import './components/share/CmsShareModal.js';

// published
import './components/published/CmsPublishedToggle.js';

// account
import './components/account/CmsAccountMenu';
import './components/account/CmsProfile';

// layout
import './components/layout/CmsActiveWorkspace';
import './components/layout/CmsSettingsMenu';

// Seo
import './components/seo/CmsSeoPreview';

// Page
import './components/pages/CmsForm';
import './components/pages/CmsGrid';
import './components/pages/CmsList';
import './components/pages/CmsPageNotFound';
