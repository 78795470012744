Vue.asyncComponent('cms-login-form',{
    data() {
        return {
            loading: false,
            steps: [
                'email',
                'password',
            ],
            errorMessage: null,
            step: 'email',
            values: {
                email: null,
                password: null,
                authenticationCode: null,
            }
        }
    },
    methods: {
        async nextStep() {
            if(this.loading) {
                return;
            }

            let valid = await this.validate(this.step);
            if (! valid) {
                return;
            }

            // Go to the next step of the login process
            let indexOfCurrentStep = this.steps.indexOf(this.step);
            this.step = this.steps[indexOfCurrentStep + 1];
        },
        focusField() {
            this.$nextTick(() => {
                this.$el.querySelector('#' + this.step)?.focus();
            });
        },
        goToStep(step) {
            this.step = step;
        },
        async validate(step) {
            this.errorMessage = null;

            this.loading = true;
            let result = await this.$post(this.$getSetting('paths.baseAuth') + '/user/validate-' + step, this.values);
            this.loading = false;

            // if authenticated, redirect to the CMS dashboard
            if(result.authenticated) {
                this.authenticated(result);
                return result.success;
            }

            // if an errorMessage occurred, show it
            if(! result.success) {
                this.errorMessage = result.errorMessage;
                return result.success;
            }

            // add 2FA step if needed
            if(result.requireAuthenticationCode) {
                this.steps.push('tfa');
                return result.success;
            }

            return result.success;
        },
        authenticated(data) {
            this.$authService.setUser({name: data.user.name});
            this.loading = true;
            this.$handleActions(data.todos, this);
        },
        passwordRecovery() {
            this.$router.push({name: 'password-recovery'});
        }
    },
    mounted() {
        // Focus on the email field from the beginning
        this.focusField();
    }
}, 'cms/form/cms-login-form.html');
